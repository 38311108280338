// Utility function to create a 2D array and initialize with 0s
function create2DArray(cols, rows) {
  let array = new Array(cols);
  for (let i = 0; i < cols; i++) {
    array[i] = new Array(rows).fill(0);
  }
  return array;
}

// Global variables
let grid; // The grid that stores the particles
const cellSize = 4; // Size of each grid cell (in pixels)
let cols, rows; // Number of columns and rows in the grid
let hueValue = 200; // Initial hue for color
let currentX = 0; // Starting x-coordinate for sand particles
let timeStep = 0; // A time variable for noise calculation
let duration = 10; // Total time in seconds before stopping the simulation
let rainCutoffTime = 7; // Default time (in seconds) when the rain should stop
let startTime; // Time when the simulation starts
let canvasHeight; // Global variable for canvas height

// Helper function to check if the given column and row index are within bounds
function isValidCol(index) {
  return index >= 0 && index < cols;
}
function isValidRow(index) {
  return index >= 0 && index < rows - 1; // Ensure we're not checking out of bounds at the bottom
}

function getViewportHeight() {
  // Use visualViewport for better support of dynamic UI changes
  return window.visualViewport
    ? window.visualViewport.height
    : window.innerHeight;
}

// Helper function to adjust rainCutoffTime based on window width
function adjustRainCutoffTime() {
  if (windowWidth <= 600) {
    duration = 5;
    rainCutoffTime = 3; // Set to 3 seconds for mobile
  } else {
    duration = 10;
    rainCutoffTime = 7; // Default value for larger screens
  }
}

window.setup = function () {
  canvasHeight = getViewportHeight(); // Set the canvas height based on the actual viewport height
  createCanvas(windowWidth, canvasHeight); // Set canvas height to dynamic value
  colorMode(HSB, 360, 255, 255, 255); // Use HSB color mode with alpha channel

  cols = floor(width / cellSize);
  rows = floor(canvasHeight / cellSize); // Use dynamic height for rows calculation
  grid = create2DArray(cols, rows); // Initialize the grid

  currentX = cols / 2; // Start the sand drop at the middle of the canvas
  timeStep = random(10000); // Randomize initial time step
  clear(); // Clear the background to make it transparent

  startTime = millis(); // Store the starting time

  adjustRainCutoffTime(); // Adjust rain cutoff time based on window width
};

window.draw = function () {
  let elapsedTime = (millis() - startTime) / 1000; // Calculate the elapsed time in seconds

  // Stop the simulation after the total duration
  if (elapsedTime > duration) {
    noLoop(); // Stop the draw loop after the duration is reached
    return;
  }

  clear(); // Make background transparent

  // Draw the sand particles with varying transparency
  for (let col = 0; col < cols; col++) {
    for (let row = 0; row < rows; row++) {
      if (grid[col][row] > 0) {
        // Calculate alpha value based on the row index
        let alpha = map(row, 0, rows, 0, 255); // Fully visible at the bottom, nearly transparent at the top
        stroke(0, 0); // No border
        fill(color(grid[col][row], 100, 255, alpha));
        square(col * cellSize, row * cellSize, cellSize);
      }
    }
  }

  // Simulate multiple steps per frame for a smoother falling effect
  for (let step = 0; step < 10; step++) {
    hueValue = (hueValue + 1) % 360; // Increment hue and loop around after 360

    // Only spawn new sand if the elapsed time is less than the rain cutoff time
    if (elapsedTime < rainCutoffTime) {
      // Add new sand at the top of the grid
      currentX += (noise(timeStep) - 0.5) * 10; // Randomly move left or right based on noise
      timeStep += 0.02; // Increase time step for smooth movement

      // Keep currentX within the grid bounds
      currentX = constrain(currentX, 0, cols - 1);

      grid[floor(currentX)][0] = hueValue; // Place sand at the top row
    }

    // Create a new grid for the next frame
    let nextGrid = create2DArray(cols, rows);

    // Loop through each cell in the grid to simulate falling sand
    for (let col = 0; col < cols; col++) {
      for (let row = 0; row < rows; row++) {
        let currentState = grid[col][row];

        if (currentState > 0) {
          // If the cell contains sand
          let below = isValidRow(row + 1) ? grid[col][row + 1] : -1; // Check the cell below

          // Randomly choose left or right direction for falling
          let dir = random(1) < 0.5 ? -1 : 1;

          // Check the cells below-left and below-right
          let belowLeft =
            isValidCol(col + dir) && isValidRow(row + 1)
              ? grid[col + dir][row + 1]
              : -1;
          let belowRight =
            isValidCol(col - dir) && isValidRow(row + 1)
              ? grid[col - dir][row + 1]
              : -1;

          // Determine where the sand can fall
          if (below === 0) {
            nextGrid[col][row + 1] = currentState; // Fall straight down
          } else if (belowLeft === 0) {
            nextGrid[col + dir][row + 1] = currentState; // Fall diagonally
          } else if (belowRight === 0) {
            nextGrid[col - dir][row + 1] = currentState; // Fall diagonally the other way
          } else {
            nextGrid[col][row] = currentState; // Stay in place
          }
        }
      }
    }

    // Update the grid with the new state for the next frame
    grid = nextGrid;
  }
};

// Adjust canvas size dynamically when window is resized
let resizeTimeout; // Variable to store the timeout ID
window.windowResized = function () {
  // Throttle the resize event
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(() => {
    canvasHeight = getViewportHeight(); // Get the updated viewport height when window is resized
    resizeCanvas(windowWidth, canvasHeight); // Resize the canvas with new dimensions

    cols = floor(width / cellSize);
    rows = floor(canvasHeight / cellSize); // Update rows based on the dynamic height
    grid = create2DArray(cols, rows); // Recreate the grid to match new window size

    adjustRainCutoffTime(); // Adjust rain cutoff time based on new window width
  }, 200); // Delay resize to avoid excessive recalculations
};
